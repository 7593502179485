
import { defineComponent, onMounted, reactive } from 'vue';
import { Badge, message } from 'ant-design-vue';
import {
  ColumnHeightOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  PlusOutlined,
  ReloadOutlined,
  SettingOutlined,
} from '@ant-design/icons-vue';
import { Container as DragContainer, Draggable } from '@/components/draggable';
import { queryMenu2, recoverMenu, removeMenu } from '@/api/permission/menu-manage.ts';
import { Pagination, TableColumn, TableColumnSortOrder, TableFilters } from '@/typing';
import { useFetchData } from '@/utils/hooks/useFetchData';
import { useFullscreen } from '@/utils/hooks/useFullscreen';
import { useTableDynamicColumns } from '@/utils/hooks/useTableColumn';
import DragIcon from '@/components/table/drag-icon.vue';
import { useStore } from 'vuex';
import { useForm } from 'ant-design-vue/es/form';
import SearchRender from '@/components/FormRender/SearchRender.vue';
import ModifyMenuManage from './modify-menu-manage/index.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'MenuManage',
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const current_org = store.getters['user/current_org'];
    const tableSize = reactive({ y: window.innerHeight - 130 });
    const statusMap = {
      true: { text: t('正常'), status: 'green' },
      false: { text: t('禁用'), status: 'red' },
    };
    const platformMap = {
      10: { text: t('PC端'), color: 'green' },
      20: { text: t('手机'), color: 'red' },
    };
    const isMenuMap = {
      true: { text: t('菜单'), color: 'green' },
      false: { text: t('树'), color: 'red' },
    };
    const status_list = [
      { text: t('全部'), value: '' },
      { text: t('正常'), value: true },
      { text: t('禁用'), value: false },
    ];
    const status = [t('正常'), t('禁用')];

    const baseColumns: TableColumn[] = [
      {
        title: t('序号'),
        dataIndex: 'index1',
        width: 60,
        slots: { customRender: 'index1' },
        fixed: 'left',
      },
      {
        title: 'ID',
        dataIndex: 'id',
        width: 60,
        slots: { customRender: 'id' },
        fixed: 'left',
      },
      {
        title: t('名称'),
        dataIndex: 'name',
        width: 300,
        slots: { customRender: 'name' },
        fixed: 'left',
      },
      {
        title: t('路由'),
        dataIndex: 'state',
        width: 200,
        slots: { customRender: 'state' },
      },
      {
        title: t('隶属组织列表'),
        dataIndex: 'org_id_list',
        width: 300,
        checked: false,
        slots: { customRender: 'org_id_list' },
      },
      {
        title: t('平台'),
        dataIndex: 'platform',
        width: 60,

        slots: { customRender: 'platform' },
      },
      {
        title: t('索引'),
        width: 60,

        dataIndex: 'index',
      },
      {
        title: t('是否菜单'),
        dataIndex: 'is_menu',
        width: 80,

        slots: { customRender: 'is_menu' },
      },

      {
        title: t('状态'),
        dataIndex: 'is_active',
        width: 60,

        slots: { customRender: 'status' },
        filters: [
          {
            text: status[0],
            value: true,
          },
          {
            text: status[1],
            value: false,
          },
        ],
      },
      {
        title: t('对应接口'),
        dataIndex: 'url_id_list',
        width: 800,
        checked: false,
        slots: { customRender: 'url_id_list' },
      },
      {
        title: t('操作'),
        dataIndex: 'action',
        width: 200,
        slots: { customRender: 'action' },
        fixed: 'right',
      },
    ];

    onMounted(() => {
      window.onresize = function () {
        tableSize.y = window.innerHeight - 130;
      };
    });
    const {
      state: columnState,
      dynamicColumns,
      dynamicColumnItems,
      handleColumnAllClick,
      handleColumnChange,
      reset,
      move,
    } = useTableDynamicColumns(baseColumns, false);
    const [elRef, screenState, { setFull, exitFull }] = useFullscreen();
    const search_modelRef = reactive({
      org_id: current_org.id,
      aim_org_id: current_org.id,
      is_active: true,
    });
    const search_config_options = {
      is_active: [
        { name: t('全部'), value: '' },
        { name: t('正常'), value: true },
        { name: t('禁用'), value: false },
      ],
    };
    const search_config = {
      settings: {
        name: t('表单名称'),
        labelAlign: 'right',
        layout: 'inline',
      },
      fields: [
        {
          type: 'remoteselect',
          name: 'aim_org_id',
          label: '目标组织',
          label_i18n: '目标组织',
          placeholder_i18n: '请选择目标组织',
          disabled: false,
          allowClear: true,
          labelKey: 'name',
          valueKey: 'id',
          placeholder: '请选择目标组织',
          datasourceType: 'remote',
          datasource: 'aim_org_id',
          mode: 'default',
          modalType: 'select-org-id',
        },
        {
          type: 'input',
          name: 'name',
          label: '菜单名称',
          label_i18n: '菜单名称',
          placeholder_i18n: '请填写菜单名称',
          disabled: false,
          allowClear: true,
          inputType: 'text',
          maxlength: 100,
          defaultValue: '',
          placeholder: '请填写菜单名称',
        },
        {
          type: 'select',
          name: 'is_active',
          label: '状态',
          label_i18n: '状态',
          placeholder_i18n: '请选择状态',
          mode: 'default',
          disabled: false,
          defaultValue: '',
          labelKey: 'name',
          valueKey: 'value',
          placeholder: '请选择状态',
          datasource: 'is_active',
        },
      ],
      rules: {
        name: [],
      },
      model: search_modelRef,
    };
    const { validateInfos: searchValidateInfos } = useForm(search_modelRef, {});

    const { stripe, reload, setPageInfo, context: state } = useFetchData(queryMenu2, {
      current: 1,
      pageSize: 2000,
      tableSize: 'small', // 'default' | 'middle' | 'small'
      stripe: true,
      requestParams: {
        ...search_modelRef,
      },
    });
    const handleTableChange = (
      { current, pageSize }: Pagination,
      filters: TableFilters,
      sorter: TableColumnSortOrder,
    ) => {
      console.log(sorter);
      setPageInfo({
        current: current ? current : state.current,
        pageSize: pageSize ? pageSize : state.pageSize,
        ...filters,
        ...search_modelRef,
      });

      reload();
    };

    const search = () => {
      setPageInfo({
        current: 1,
        ...search_modelRef,
      });
      reload();
    };

    const editModal = reactive({
      visible: false,
      title: '',
      aim_org_id: '',
      form: {},
    });

    let currentRecord = reactive({ name: '' });

    const handleOpenEdit = (record: any) => {
      let list: any[] = [];
      let list1: any[] = [];
      if (record.org_id_list && record.org_id_list.length > 0) {
        record.org_id_list.forEach((item: any) => {
          list.push(item.id);
        });
      }
      if (record.url_id_list && record.url_id_list.length > 0) {
        record.url_id_list.forEach((item: any) => {
          list1.push(item.id);
        });
      }
      editModal.visible = true;
      editModal.title = t('修改菜单');
      editModal.form = {
        ...record,
        org_ids: list,
        url_ids: list1,
      };
      editModal.aim_org_id = search_modelRef.aim_org_id;
      currentRecord = record;
    };
    const handleCreatEdit = () => {
      editModal.visible = true;
      editModal.aim_org_id = search_modelRef.aim_org_id;
      editModal.title = t('新建菜单');
      editModal.form = {};
    };

    const handleDelete = (record: any) => {
      removeMenu({ menu_id: record.id, org_id: current_org.id }).then((res: any) => {
        record.is_active = false;
        message.success(res.message);
      });
    };
    const handleRecover = (record: any) => {
      recoverMenu({ menu_id: record.id, org_id: current_org.id }).then((res: any) => {
        record.is_active = true;
        message.success(res.message);
      });
    };

    const handlerOk = (data: any) => {
      if (data && data.id) {
        editModal.visible = false;
        reload();
      } else {
        editModal.visible = false;
        reload();
      }
    };
    const handleApiUrl = () => {};

    return {
      t,
      isMenuMap,
      statusMap,
      platformMap,
      tableSize,
      state,
      status_list,
      stripe,
      columnState,
      dynamicColumns,
      dynamicColumnItems,

      handlerOk,
      currentRecord,
      search_modelRef,
      searchValidateInfos,
      // fullscreen
      elRef,
      screenState,
      setFull,
      exitFull,

      handleTableChange,
      search,

      handleColumnChange,
      handleColumnAllClick,
      reset,
      move,
      current_org,
      message,
      // edit
      // edit_model_config,
      // edit_model_config_options,
      editModal,
      handleOpenEdit,
      handleCreatEdit,
      handleDelete,
      handleRecover,
      handleApiUrl,
      reload,
      search_config,
      search_config_options,
    };
  },
  components: {
    SearchRender,
    DragIcon,
    PlusOutlined,
    ReloadOutlined,
    ColumnHeightOutlined,
    SettingOutlined,
    FullscreenOutlined,
    FullscreenExitOutlined,
    Draggable,
    DragContainer,
    [Badge.name]: Badge,
    ModifyMenuManage,
  },
});
