<template>
  <page-container3>
    <div class="ant-pro-table">
      <div class="ant-pro-table-search">
        <search-render
          ref="config_render"
          :form="search_config"
          :model="search_config.model"
          :options="search_config_options"
          :validateInfos="searchValidateInfos"
          @search="search"
        />
      </div>
      <a-card :body-style="{ padding: 0 }" ref="elRef">
        <div class="ant-pro-table-list-toolbar">
          <div class="ant-pro-table-list-toolbar-container">
            <div class="ant-pro-table-list-toolbar-left">
              <div class="ant-pro-table-list-toolbar-title">{{ t('菜单管理') }}</div>
            </div>
            <div class="ant-pro-table-list-toolbar-right">
              <a-space align="center">
                <a-button type="primary" size="small" @click="() => handleCreatEdit()">
                  <plus-outlined />
                  {{ t('新建菜单') }}
                </a-button>
                <div class="ant-pro-table-list-toolbar-setting-item">
                  <a-tooltip :title="t('表格斑马纹')">
                    <a-switch
                      :checked-children="t('开')"
                      :un-checked-children="t('关')"
                      v-model:checked="state.stripe"
                    />
                  </a-tooltip>
                </div>
              </a-space>
              <div class="ant-pro-table-list-toolbar-divider">
                <a-divider type="vertical" />
              </div>
              <div class="ant-pro-table-list-toolbar-setting-item">
                <a-tooltip :title="t('刷新')">
                  <reload-outlined @click="handleTableChange" />
                </a-tooltip>
              </div>
              <div class="ant-pro-table-list-toolbar-setting-item">
                <a-tooltip :title="t('密度')">
                  <a-dropdown :trigger="['click']" placement="bottomRight">
                    <column-height-outlined />
                    <template #overlay>
                      <a-menu
                        style="width: 80px"
                        :selected-keys="[state.tableSize]"
                        @click="
                          ({ key }) => {
                            state.tableSize = key;
                          }
                        "
                      >
                        <a-menu-item key="default">
                          <a href="javascript:">{{ t('默认') }}</a>
                        </a-menu-item>
                        <a-menu-item key="middle">
                          <a href="javascript:">{{ t('中等') }}</a>
                        </a-menu-item>
                        <a-menu-item key="small">
                          <a href="javascript:">{{ t('紧凑') }}</a>
                        </a-menu-item>
                      </a-menu>
                    </template>
                  </a-dropdown>
                </a-tooltip>
              </div>
              <div class="ant-pro-table-list-toolbar-setting-item">
                <a-popover
                  placement="bottomRight"
                  arrowPointAtCenter
                  trigger="click"
                  overlayClassName="ant-pro-table-column-setting-overlay"
                >
                  <template #title>
                    <div class="ant-pro-table-column-setting-title">
                      <a-checkbox
                        v-model:checked="columnState.checkAll"
                        :indeterminate="columnState.indeterminate"
                        @change="handleColumnAllClick"
                      >
                        {{ t('列展示') }}
                      </a-checkbox>
                      <a @click="reset">{{ t('重置') }}</a>
                    </div>
                  </template>
                  <template #content>
                    <span class="ant-pro-table-column-setting-list">
                      <drag-container
                        lockAxis="y"
                        dragClass="ant-pro-table-drag-ghost"
                        dropClass="ant-pro-table-drop-ghost"
                        @drop="({ removedIndex, addedIndex }) => move(removedIndex, addedIndex)"
                      >
                        <draggable :key="column.key" v-for="column in dynamicColumnItems">
                          <div class="ant-pro-table-column-setting-list-item">
                            <drag-icon />
                            <a-checkbox
                              :checked="column.checked"
                              @change="handleColumnChange($event, column)"
                            >
                              {{ column.label }}
                            </a-checkbox>
                          </div>
                        </draggable>
                      </drag-container>
                    </span>
                  </template>
                  <a-tooltip :title="t('列设置')">
                    <setting-outlined />
                  </a-tooltip>
                </a-popover>
              </div>
              <div class="ant-pro-table-list-toolbar-setting-item">
                <a-tooltip :title="screenState ? t('退出全屏') : t('全屏')">
                  <fullscreen-outlined v-if="!screenState" @click="setFull" />
                  <fullscreen-exit-outlined v-else @click="exitFull" />
                </a-tooltip>
              </div>
            </div>
          </div>
        </div>
        <a-affix :offset-top="top">
          <a-table
            :scroll="{ y: tableSize.y, x: columnState.tableWidth }"
            :row-class-name="stripe"
            :size="state.tableSize"
            :loading="state.loading"
            :columns="dynamicColumns"
            :data-source="state.dataSource"
            :indentSize="0"
            :rowKey="'id'"
            :pagination="{
              current: state.current,
              pageSize: state.pageSize,
              total: state.total,
            }"
            @change="handleTableChange"
          >
            <template #index1="{ index }">
              {{ state.pageSize * (state.current - 1) + index + 1 }}
            </template>
            ·
            <template #id="{ text }">
              <span>#{{ text }}</span>
            </template>
            <template #status="{ text }">
              <a-tag :color="statusMap[text].status">
                {{ statusMap[text].text }}
              </a-tag>
            </template>
            <template #platform="{ text }">
              <a-tag :color="platformMap[text].color">
                {{ platformMap[text].text }}
              </a-tag>
            </template>
            <template #org_id_list="{ text }">
              <a-tag color="green" v-for="item in text" :key="item.id">
                {{ item.name }}
              </a-tag>
            </template>
            <template #is_menu="{ text }">
              <a-tag :color="isMenuMap[text].color">
                {{ isMenuMap[text].text }}
              </a-tag>
            </template>
            <template #url_id_list="{ text }">
              <a-tag color="green" v-for="item in text" :key="item.id">
                {{ item.name }}:
                {{ item.url }}
              </a-tag>
            </template>
            <template #action="{ record }">
              <a-button
                size="small"
                type="primary"
                @click="() => handleOpenEdit(record)"
                v-if="record.is_active"
              >
                {{ t('修改') }}
              </a-button>
              <a-popconfirm
                placement="top"
                :cancel-text="t('取消')"
                :ok-text="t('删除')"
                v-if="record.is_active"
                @confirm="() => handleDelete(record)"
              >
                <template #title>
                  <p>{{ t('是否删除') }}?</p>
                </template>
                <a-button size="small" type="danger">{{ t('删除') }}</a-button>
              </a-popconfirm>

              <a-button
                type="danger"
                size="small"
                @click="() => handleRecover(record)"
                v-if="!record.is_active"
              >
                {{ t('恢复') }}
              </a-button>
            </template>
          </a-table>
        </a-affix>
      </a-card>
    </div>
    <modify-menu-manage
      v-if="editModal.visible"
      v-bind="editModal"
      @cancel="
        () => {
          editModal.visible = false;
        }
      "
      @ok="handlerOk"
    />
  </page-container3>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive } from 'vue';
import { Badge, message } from 'ant-design-vue';
import {
  ColumnHeightOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  PlusOutlined,
  ReloadOutlined,
  SettingOutlined,
} from '@ant-design/icons-vue';
import { Container as DragContainer, Draggable } from '@/components/draggable';
import { queryMenu2, recoverMenu, removeMenu } from '@/api/permission/menu-manage.ts';
import { Pagination, TableColumn, TableColumnSortOrder, TableFilters } from '@/typing';
import { useFetchData } from '@/utils/hooks/useFetchData';
import { useFullscreen } from '@/utils/hooks/useFullscreen';
import { useTableDynamicColumns } from '@/utils/hooks/useTableColumn';
import DragIcon from '@/components/table/drag-icon.vue';
import { useStore } from 'vuex';
import { useForm } from 'ant-design-vue/es/form';
import SearchRender from '@/components/FormRender/SearchRender.vue';
import ModifyMenuManage from './modify-menu-manage/index.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'MenuManage',
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const current_org = store.getters['user/current_org'];
    const tableSize = reactive({ y: window.innerHeight - 130 });
    const statusMap = {
      true: { text: t('正常'), status: 'green' },
      false: { text: t('禁用'), status: 'red' },
    };
    const platformMap = {
      10: { text: t('PC端'), color: 'green' },
      20: { text: t('手机'), color: 'red' },
    };
    const isMenuMap = {
      true: { text: t('菜单'), color: 'green' },
      false: { text: t('树'), color: 'red' },
    };
    const status_list = [
      { text: t('全部'), value: '' },
      { text: t('正常'), value: true },
      { text: t('禁用'), value: false },
    ];
    const status = [t('正常'), t('禁用')];

    const baseColumns: TableColumn[] = [
      {
        title: t('序号'),
        dataIndex: 'index1',
        width: 60,
        slots: { customRender: 'index1' },
        fixed: 'left',
      },
      {
        title: 'ID',
        dataIndex: 'id',
        width: 60,
        slots: { customRender: 'id' },
        fixed: 'left',
      },
      {
        title: t('名称'),
        dataIndex: 'name',
        width: 300,
        slots: { customRender: 'name' },
        fixed: 'left',
      },
      {
        title: t('路由'),
        dataIndex: 'state',
        width: 200,
        slots: { customRender: 'state' },
      },
      {
        title: t('隶属组织列表'),
        dataIndex: 'org_id_list',
        width: 300,
        checked: false,
        slots: { customRender: 'org_id_list' },
      },
      {
        title: t('平台'),
        dataIndex: 'platform',
        width: 60,

        slots: { customRender: 'platform' },
      },
      {
        title: t('索引'),
        width: 60,

        dataIndex: 'index',
      },
      {
        title: t('是否菜单'),
        dataIndex: 'is_menu',
        width: 80,

        slots: { customRender: 'is_menu' },
      },

      {
        title: t('状态'),
        dataIndex: 'is_active',
        width: 60,

        slots: { customRender: 'status' },
        filters: [
          {
            text: status[0],
            value: true,
          },
          {
            text: status[1],
            value: false,
          },
        ],
      },
      {
        title: t('对应接口'),
        dataIndex: 'url_id_list',
        width: 800,
        checked: false,
        slots: { customRender: 'url_id_list' },
      },
      {
        title: t('操作'),
        dataIndex: 'action',
        width: 200,
        slots: { customRender: 'action' },
        fixed: 'right',
      },
    ];

    onMounted(() => {
      window.onresize = function () {
        tableSize.y = window.innerHeight - 130;
      };
    });
    const {
      state: columnState,
      dynamicColumns,
      dynamicColumnItems,
      handleColumnAllClick,
      handleColumnChange,
      reset,
      move,
    } = useTableDynamicColumns(baseColumns, false);
    const [elRef, screenState, { setFull, exitFull }] = useFullscreen();
    const search_modelRef = reactive({
      org_id: current_org.id,
      aim_org_id: current_org.id,
      is_active: true,
    });
    const search_config_options = {
      is_active: [
        { name: t('全部'), value: '' },
        { name: t('正常'), value: true },
        { name: t('禁用'), value: false },
      ],
    };
    const search_config = {
      settings: {
        name: t('表单名称'),
        labelAlign: 'right',
        layout: 'inline',
      },
      fields: [
        {
          type: 'remoteselect',
          name: 'aim_org_id',
          label: '目标组织',
          label_i18n: '目标组织',
          placeholder_i18n: '请选择目标组织',
          disabled: false,
          allowClear: true,
          labelKey: 'name',
          valueKey: 'id',
          placeholder: '请选择目标组织',
          datasourceType: 'remote',
          datasource: 'aim_org_id',
          mode: 'default',
          modalType: 'select-org-id',
        },
        {
          type: 'input',
          name: 'name',
          label: '菜单名称',
          label_i18n: '菜单名称',
          placeholder_i18n: '请填写菜单名称',
          disabled: false,
          allowClear: true,
          inputType: 'text',
          maxlength: 100,
          defaultValue: '',
          placeholder: '请填写菜单名称',
        },
        {
          type: 'select',
          name: 'is_active',
          label: '状态',
          label_i18n: '状态',
          placeholder_i18n: '请选择状态',
          mode: 'default',
          disabled: false,
          defaultValue: '',
          labelKey: 'name',
          valueKey: 'value',
          placeholder: '请选择状态',
          datasource: 'is_active',
        },
      ],
      rules: {
        name: [],
      },
      model: search_modelRef,
    };
    const { validateInfos: searchValidateInfos } = useForm(search_modelRef, {});

    const { stripe, reload, setPageInfo, context: state } = useFetchData(queryMenu2, {
      current: 1,
      pageSize: 2000,
      tableSize: 'small', // 'default' | 'middle' | 'small'
      stripe: true,
      requestParams: {
        ...search_modelRef,
      },
    });
    const handleTableChange = (
      { current, pageSize }: Pagination,
      filters: TableFilters,
      sorter: TableColumnSortOrder,
    ) => {
      console.log(sorter);
      setPageInfo({
        current: current ? current : state.current,
        pageSize: pageSize ? pageSize : state.pageSize,
        ...filters,
        ...search_modelRef,
      });

      reload();
    };

    const search = () => {
      setPageInfo({
        current: 1,
        ...search_modelRef,
      });
      reload();
    };

    const editModal = reactive({
      visible: false,
      title: '',
      aim_org_id: '',
      form: {},
    });

    let currentRecord = reactive({ name: '' });

    const handleOpenEdit = (record: any) => {
      let list: any[] = [];
      let list1: any[] = [];
      if (record.org_id_list && record.org_id_list.length > 0) {
        record.org_id_list.forEach((item: any) => {
          list.push(item.id);
        });
      }
      if (record.url_id_list && record.url_id_list.length > 0) {
        record.url_id_list.forEach((item: any) => {
          list1.push(item.id);
        });
      }
      editModal.visible = true;
      editModal.title = t('修改菜单');
      editModal.form = {
        ...record,
        org_ids: list,
        url_ids: list1,
      };
      editModal.aim_org_id = search_modelRef.aim_org_id;
      currentRecord = record;
    };
    const handleCreatEdit = () => {
      editModal.visible = true;
      editModal.aim_org_id = search_modelRef.aim_org_id;
      editModal.title = t('新建菜单');
      editModal.form = {};
    };

    const handleDelete = (record: any) => {
      removeMenu({ menu_id: record.id, org_id: current_org.id }).then((res: any) => {
        record.is_active = false;
        message.success(res.message);
      });
    };
    const handleRecover = (record: any) => {
      recoverMenu({ menu_id: record.id, org_id: current_org.id }).then((res: any) => {
        record.is_active = true;
        message.success(res.message);
      });
    };

    const handlerOk = (data: any) => {
      if (data && data.id) {
        editModal.visible = false;
        reload();
      } else {
        editModal.visible = false;
        reload();
      }
    };
    const handleApiUrl = () => {};

    return {
      t,
      isMenuMap,
      statusMap,
      platformMap,
      tableSize,
      state,
      status_list,
      stripe,
      columnState,
      dynamicColumns,
      dynamicColumnItems,

      handlerOk,
      currentRecord,
      search_modelRef,
      searchValidateInfos,
      // fullscreen
      elRef,
      screenState,
      setFull,
      exitFull,

      handleTableChange,
      search,

      handleColumnChange,
      handleColumnAllClick,
      reset,
      move,
      current_org,
      message,
      // edit
      // edit_model_config,
      // edit_model_config_options,
      editModal,
      handleOpenEdit,
      handleCreatEdit,
      handleDelete,
      handleRecover,
      handleApiUrl,
      reload,
      search_config,
      search_config_options,
    };
  },
  components: {
    SearchRender,
    DragIcon,
    PlusOutlined,
    ReloadOutlined,
    ColumnHeightOutlined,
    SettingOutlined,
    FullscreenOutlined,
    FullscreenExitOutlined,
    Draggable,
    DragContainer,
    [Badge.name]: Badge,
    ModifyMenuManage,
  },
});
</script>
