
import { useForm } from 'ant-design-vue/es/form';
import { defineComponent, onMounted, reactive, ref, watch } from 'vue';
import { addMenu, updateMenu } from '@/api/permission/menu-manage';
import { message } from 'ant-design-vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';


export default defineComponent({
  name: 'modifyMenuManage',
  props: {
    title: {
      type: String,
      default: () => '',
    },
    aim_org_id: {
      type: Number,
      default: () => 1,
    },
    form: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['ok', 'cancel'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const loading = ref(false);
    const treeData: any = reactive([]);
    const modelRef = reactive({ ...props.form });
    const store = useStore();
    const current_org = store.getters['user/current_org'];
    const search_config: any = reactive({
      aim_org_id: props.aim_org_id,
      org_id: current_org?.id,
    });
    // edit
    const edit_model_config = {
      settings: {
        name: '表单名称',
        labelAlign: 'right',
        layout: 'vertical',
      },
      fields: [
        {
          type: 'input',
          name: 'name',
          label: '菜单名称',
          label_i18n: '菜单名称',
          placeholder_i18n: '请填写菜单名称',
          disabled: false,
          allowClear: true,
          inputType: 'text',
          maxlength: 100,
          defaultValue: '',
          placeholder: '请填写菜单名称',
        },
        {
          type: 'select',
          name: 'platform',
          label: '平台',
          label_i18n: '平台',
          placeholder_i18n: '请选择平台',
          disabled: false,
          defaultValue: '',
          labelKey: 'name',
          valueKey: 'value',
          placeholder: '请选择平台',
          datasource: 'platform',
          mode: 'default',
        },
        {
          type: 'input',
          name: 'state',
          label: '路由',
          label_i18n: '路由',
          placeholder_i18n: '请填写路由',
          disabled: false,
          allowClear: true,
          inputType: 'text',
          maxlength: 100,
          defaultValue: '',
          placeholder: '请填写路由',
        },
        {
          type: 'input',
          name: 'index',
          label: '菜单索引',
          label_i18n: '菜单索引',
          placeholder_i18n: '请填写菜单索引',
          disabled: false,
          allowClear: true,
          inputType: 'text',
          maxlength: 100,
          defaultValue: '',
          placeholder: '请填写菜单索引',
        },
        {
          type: 'remotetreeselect',
          name: 'parent_id',
          label: '父级菜单',
          label_i18n: '父级菜单',
          placeholder_i18n: '请选择父级菜单',
          disabled: false,
          allowClear: true,
          defaultValue: '',
          valueKey: 'value',
          labelKey: 'title',
          placeholder: '请选择父级菜单',
          modalType: 'menu-tree-select',
          search_config: search_config,
        },
        {
          type: 'radio',
          name: 'is_menu',
          label: '是否为菜单',
          label_i18n: '是否为菜单',
          disabled: false,
          defaultValue: true,
          labelKey: 'name',
          valueKey: 'value',
          datasource: 'is_menu',
        },
        {
          type: 'remoteselect',
          name: 'org_ids',
          label: '隶属组织',
          label_i18n: '隶属组织',
          placeholder_i18n: '请选择组织',
          disabled: false,
          defaultValue: '',
          labelKey: 'name',
          valueKey: 'id',
          placeholder: '请选择组织',
          datasource: 'org_ids',
          show_search: false,
          maxTagCount: 5,
          mode: 'multiple',
          modalType: 'select-org-id',
        },
        {
          type: 'remotemodal',
          name: 'url_ids',
          label: '接口',
          label_i18n: '接口',
          placeholder_i18n: '接口',
          disabled: false,
          defaultValue: '',
          labelKey: 'name',
          valueKey: 'id',
          placeholder: '接口',
          datasource: 'urls',
          show_search: false,
          maxTagCount: 5,
          mode: 'multiple',
          title: t('选择多个API接口'),
          modalType: 'api-url',
        },
      ],
      rules: {
        name: [{ required: true, message: t('菜单名称必须填写') }],
      },
      model: reactive({
        id: null,
        name: '',
        platform: 10,
        state: '',
        index: '',
        parent_id: '',
        is_menu: true,
        org_ids: [],
        url_ids: [],
      }),
      options: {},
    };
    if (modelRef && modelRef.id) {
      edit_model_config.model = {
        id: modelRef.id,
        name: modelRef.name,
        platform: modelRef.platform,
        state: modelRef.state,
        index: modelRef.index,
        parent_id: modelRef.parent?.id,
        is_menu: modelRef.is_menu,
        org_ids: modelRef.org_ids,
        url_ids: modelRef.url_ids,
      };
    }
    console.log('edit_model_config.model', edit_model_config.model);
    // 设置必要的默认值
    const edit_model_config_options = {
      platform: [
        { name: t('PC'), value: 10 },
        { name: t('手机端'), value: 20 },
      ],
      is_menu: [
        { name: t('是'), value: true },
        { name: t('否'), value: false },
      ],
      // parent_id: [
      //   {
      //     title: 'Node1',
      //     value: '0-0',
      //     key: '0-0',
      //     children: [
      //       {
      //         value: '0-0-1',
      //         key: '0-0-1',
      //       },
      //       {
      //         title: 'Child Node2',
      //         value: '0-0-2',
      //         key: '0-0-2',
      //       },
      //     ],
      //   },
      //   {
      //     title: 'Node2',
      //     value: '0-1',
      //     key: '0-1',
      //   },
      // ],
    };

    const rulesRef = reactive({ ...edit_model_config.rules });
    const value = ref<string>();

    watch(value, () => {
      console.log(value.value);
    });
    const { resetFields, validate, validateInfos } = useForm(edit_model_config.model, rulesRef);
    const handleSubmit = (e: Event) => {
      e.preventDefault();
      loading.value = true;
      validate()
        .then(() => {
          let org_ids = '';
          let url_ids = '';
          if (edit_model_config.model.org_ids && edit_model_config.model.org_ids.length > 0) {
            org_ids = edit_model_config.model.org_ids.join(',');
          }
          if (edit_model_config.model.url_ids && edit_model_config.model.url_ids.length > 0) {
            url_ids = edit_model_config.model.url_ids.join(',');
          }
          if (!edit_model_config.model.id) {
            addMenu({
              ...edit_model_config.model,
              org_id: current_org.id,
              org_ids: org_ids,
              url_ids: url_ids,
            }).then(() => {
              message.success(t('新增菜单成功'));
              emit('ok');
            });
          } else {
            updateMenu({
              ...edit_model_config.model,
              org_id: current_org.id,
              org_ids: org_ids,
              menu_id: edit_model_config.model.id,
            }).then(res => {
              message.success(t('修改菜单成功'));
              emit('ok', res);
            });
          }
          loading.value = false;
        })
        .finally(() => {
          loading.value = false;
        })
        .catch(err => {
          console.log('error', err);
          loading.value = false;
        });
    };
    onMounted(() => {
      // queryMenu({ org_id: current_org.id }).then(res => {
      //   treeData.length = 0;
      //   treeData.value = res.data;
      // });
    });
    return {
      props,
      loading,
      handleSubmit,
      search_config,
      modelRef,
      resetFields,
      validateInfos,
      treeData,
      edit_model_config,
      edit_model_config_options,
      //
      value,
    };
  },
});
